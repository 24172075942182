<template>
  <ValidationObserver ref="observer" tag="div" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(validate)">
      <password-update-from />
      <BaseBtn text="Update" />
      <FlashMessage :message="message" :error="error" />
    </form>
  </ValidationObserver>
</template>

<script>
import { getError } from "@/utils/helpers";
import BaseBtn from "@/components/BaseBtn";
import AuthService from "@/services/AuthService";
import FlashMessage from "@/components/FlashMessage";
import PasswordUpdateFrom from "@/components/PasswordUpdateFrom.vue";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  //setInteractionMode,
} from "vee-validate";
import { required, confirmed } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});

extend("confirmed", {
  ...confirmed,
  message: "New password must be the same",
});

export default {
  name: "UpdatePassword",
  components: {
    BaseBtn,
    FlashMessage,
    PasswordUpdateFrom,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      payload: {
        password: null,
        password_confirmation: null,
      },

      error: null,
      message: null,
    };
  },
  props: {
    attributes: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {
    this.$eventBus.$on("users:password", (val) => {
      this.payload.password = val;
    });

    this.$eventBus.$on("users:passwordConfirm", (val) => {
      this.payload.password_confirmation = val;
    });
  },
  methods: {
    validate() {
      this.error = null;
      this.message = null;
      AuthService.updatePassword(this.payload)
        .then(() => (this.message = "Password updated."))
        .then(() => this.$eventBus.$emit("users:password", true))
        .then(() => this.$parent.close())
        .catch((error) => (this.error = getError(error)));
    },
  },
  mounted() {
    if (this.attributes.payload) {
      this.payload = this.attributes.payload;
    }
  }
};
</script>
