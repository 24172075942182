<template>
  <div>
    <ValidationProvider v-slot="{ errors }" tag="div" rules="required|confirmed:password-confirm" class="mb-2">
      <label for="password" class="text-gray-500">Password</label>
      <input type="password" name="password" id="password" v-model="password" class="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500" />
      <p class="text-sm text-red-600" v-if="errors[0]">{{ errors[0] }}</p>
    </ValidationProvider>

    <ValidationProvider v-slot="{ errors }" tag="div" rules="required" class="mb-2" vid="password-confirm">
      <label for="password-confirm" class="text-gray-500">Confirm Password</label>
      <input type="password" name="password-confirm" id="password-confirm" v-model="passwordConfirm" class="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500" />
      <p class="text-sm text-red-600" v-if="errors[0]">{{ errors[0] }}</p>
    </ValidationProvider>
  </div>
</template>

<script>
import {
  ValidationProvider,
  //setInteractionMode,
} from "vee-validate";

export default {
    
  name: "PasswordUpdateFrom",
  components: {
    ValidationProvider,
  },
  watch: {
    password: {
      handler: function (value) {
        this.$eventBus.$emit("users:password", value);
      },
    },
    passwordConfirm: {
      handler: function (value) {
        this.$eventBus.$emit("users:passwordConfirm", value);
      },
    },
  },
  
  data() {
    return {
      currentPassword: null,
      password: null,
      passwordConfirm: null,
      error: null,
      message: null,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>